import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faSignOutAlt, faBell } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { UserContext } from "./services/UserContext";
import Swal from 'sweetalert2';
import '@sweetalert2/theme-dark/dark.min.css';
import {useTranslation} from "react-i18next";

const Header = ({ toggleSidebar, isSidebarOpen, notificationReceivedAt }) => {
    const navigate = useNavigate();
    const { userRoles, removeUserRoles } = useContext(UserContext);
    const [notifications, setNotifications] = useState([]);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [showNotifications, setShowNotifications] = useState(false);

    useEffect(() => {
        if(userRoles.includes('farmer')){
            fetchNotifications();
        }

    }, [notificationReceivedAt,userRoles]);
    const fetchNotifications = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/pond/all/notifications`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const data = await response.json();
            setNotifications(data.data);
            setNotificationsCount(data.count);
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    };
    const handleLogout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to log out?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, log out!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                const token = localStorage.getItem('token');
                if (token) {
                    fetch(`${process.env.REACT_APP_BASE_URL}/api/auth/logout`, {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    })
                        .then((response) => {
                            if (response.ok) {
                                localStorage.removeItem('token');
                                localStorage.removeItem('userRoles');
                                localStorage.removeItem('fcm_token');
                                removeUserRoles();
                                Swal.fire('Logged Out!', 'You have been successfully logged out.', 'success');
                                navigate('/login');
                            } else {
                                Swal.fire('Error!', 'Failed to log out from the server.', 'error');
                            }
                        })
                        .catch((error) => {
                            console.error('Error during logout:', error);
                            Swal.fire('Error!', 'There was a problem logging out.', 'error');
                        });
                } else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('userRoles');
                    removeUserRoles();
                    Swal.fire('Logged Out!', 'You have been successfully logged out.', 'success');
                    navigate('/login');
                }
            }
        });
    };

    const markNotificationsAsSeen = async () => {
        const token = localStorage.getItem('token');
        const notifIds = notifications.map(notification => notification.id);


        try {
            await fetch(`${process.env.REACT_APP_BASE_URL}/api/pond/seen/notifications`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ notif_id: notifIds })
            });
            setNotificationsCount(0); // Reset count once notifications are marked as seen
        } catch (error) {
            console.error("Error marking notifications as seen:", error);
        }
    };

    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
        if (!showNotifications) {
            markNotificationsAsSeen(); // Mark as seen only when opening the dropdown
        }
    };

    const {i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div
            className={`bg-sky-600 shadow p-4 flex justify-between items-center fixed top-0 left-0 right-0 z-20 transition-all duration-300 ${isSidebarOpen ? 'ml-64' : 'ml-0'}`}>
            <button onClick={toggleSidebar}
                    className="text-2xl bg-sky-800 text-white font-bold px-2 rounded transition-transform duration-300 ease-in-out ml-[-25px] pl-5">
                <FontAwesomeIcon
                    icon={isSidebarOpen ? faTimes : faBars}
                    className="transition-transform duration-300 ease-in-out transform"
                    style={{ transform: isSidebarOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                />
            </button>

            <div className="flex items-center space-x-10">
                {/* Notification Icon with Badge, only for 'farmer' role */}
                {userRoles.includes('farmer') && (
                    <div className="relative">
                        <FontAwesomeIcon icon={faBell} className="text-white text-2xl cursor-pointer"
                                         onClick={toggleNotifications}/>
                        {notificationsCount > 0 && (
                            <span
                                className="absolute -top-1 -right-2 h-5 w-5 bg-red-500 text-white text-xs rounded-full flex items-center justify-center">
                                {notificationsCount}
                            </span>
                        )}

                        {/* Notification Dropdown */}
                        {showNotifications && (
                            <div
                                className="absolute right-0 mt-2 w-80 bg-white text-gray-800 rounded-lg shadow-lg p-4 z-30">
                                <h4 className="font-bold mb-2">Notifications</h4>
                                <ul className="max-h-48 overflow-y-auto">
                                    {notifications.map((notification, index) => (
                                        <li key={index} className="mb-2 p-2 rounded hover:bg-gray-100">
                                            <p><strong>Pond:</strong> {notification.pond}</p>
                                            <p>
                                                <strong>Sensor:</strong> {notification.sensor} ({notification.value} {notification.unit})
                                            </p>
                                            <p><strong>Status:</strong> <span
                                                className={`text-${notification.status === 'danger' ? 'red' : 'yellow'}-500 font-bold`}>{notification.status.toUpperCase()}</span>
                                            </p>
                                            <p><small className="text-gray-500">{notification.time}</small></p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                )}

                {/* Logout Button */}

                <div className="flex space-x-1 justify-center items-center bg-gray-100 p-1 rounded-md shadow-md">
                    <button
                        className="bg-gradient-to-r from-blue-500 to-blue-700 text-white px-1 py-1 rounded hover:from-blue-600 hover:to-blue-800 shadow-lg transform transition-transform duration-300 hover:scale-105"
                        onClick={() => changeLanguage('en')}>
                        English
                    </button>
                    <button
                        className="bg-gradient-to-r from-green-500 to-green-700 text-white px-1 py-1 rounded hover:from-green-600 hover:to-green-800 shadow-lg transform transition-transform duration-300 hover:scale-105"
                        onClick={() => changeLanguage('bn')}>
                        বাংলা
                    </button>
                </div>
                <button
                    onClick={handleLogout}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 flex items-center"
                >
                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-2"/>
                    Logout
                </button>
            </div>
        </div>
    );
}

export default Header;
