import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import '@sweetalert2/theme-dark/dark.min.css';
import {useTranslation} from "react-i18next";

const CultureCycleManagement = () => {
    const [cultureCycles, setCultureCycles] = useState([]);
    const [ponds, setPonds] = useState([]);
    const [fishList, setFishList] = useState([]);
    const [formData, setFormData] = useState({
        user_id: '',
        farm_id: '',
        name: '',
        culture_type: '',
        start_date: '',
        ponds: [],
        fish: [],
    });
    const [editing, setEditing] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        fetchCultureCycles();
        fetchPonds();
        fetchFishList();
    }, []);

    const fetchCultureCycles = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/culture-cycle`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setCultureCycles(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch culture cycles.', 'error');
        }
    };

    const fetchPonds = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPonds(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch ponds.', 'error');
        }
    };

    const fetchFishList = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/fish`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setFishList(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch fish list.', 'error');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleMultiSelectChange = (selectedOptions, key) => {
        setFormData({
            ...formData,
            [key]: selectedOptions ? selectedOptions.map(option => option.value) : [],
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        try {
            if (editing) {
                await axios.put(`${process.env.REACT_APP_BASE_URL}/api/culture-cycle/${editing._id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                Swal.fire('Success', 'Culture cycle updated successfully', 'success');
            } else {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/api/culture-cycle`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                Swal.fire('Success', 'Culture cycle created successfully', 'success');
            }
            setFormData({
                user_id: '',
                farm_id: '',
                name: '',
                culture_type: '',
                start_date: '',
                ponds: [],
                fish: [],
            });
            setEditing(null);
            setIsModalOpen(false);
            fetchCultureCycles();
        } catch (error) {
            Swal.fire('Error', 'Failed to save culture cycle.', 'error');
        }
    };

    const handleEdit = (cultureCycle) => {
        setFormData({
            user_id: cultureCycle.user_id._id,
            farm_id: cultureCycle.farm_id._id,
            name: cultureCycle.name,
            culture_type: cultureCycle.culture_type,
            start_date: cultureCycle.start_date.split('T')[0],
            ponds: cultureCycle.ponds,
            fish: cultureCycle.fish,
        });
        setEditing(cultureCycle);
        setIsModalOpen(true);
    };

    const handleDelete = async (id) => {
        const token = localStorage.getItem('token');
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to delete this culture cycle?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!',
            });

            if (result.isConfirmed) {
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/culture-cycle/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                Swal.fire('Deleted!', 'Culture cycle has been deleted.', 'success');
                fetchCultureCycles();
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to delete culture cycle.', 'error');
        }
    };

    const openModal = () => {
        setFormData({
            user_id: '',
            farm_id: '',
            name: '',
            culture_type: '',
            start_date: '',
            ponds: [],
            fish: [],
        });
        setEditing(null);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const pondOptions = ponds.map(pond => ({ value: pond._id, label: pond.name }));
    const fishOptions = fishList.map(fish => ({ value: fish._id, label: fish.name }));

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">{t('culture_cycle_management')}</h1>
            <button
                onClick={openModal}
                className="bg-blue-500 text-white p-3 rounded mb-6 hover:bg-blue-600 transition duration-200"
            >
                Add New Culture Cycle
            </button>

            <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-2xl font-semibold mb-4">{t('culture_cycle')}</h2>
                {cultureCycles.length > 0 ? (
                    <table className="min-w-full bg-white">
                        <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">Name</th>
                            <th className="py-2 px-4 border-b">Culture Type</th>
                            <th className="py-2 px-4 border-b">Start Date</th>
                            <th className="py-2 px-4 border-b">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {cultureCycles.map((cycle) => (
                            <tr key={cycle._id}>
                                <td className="py-2 px-4 border-b text-center">{cycle.name}</td>
                                <td className="py-2 px-4 border-b text-center">{cycle.culture_type}</td>
                                <td className="py-2 px-4 border-b text-center">{cycle.start_date.split('T')[0]}</td>
                                <td className="py-2 px-4 border-b text-center">
                                    <button
                                        onClick={() => handleEdit(cycle)}
                                        className="bg-yellow-500 text-white px-4 py-2 rounded mr-2 hover:bg-yellow-600"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleDelete(cycle._id)}
                                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No culture cycles found.</p>
                )}
            </div>

            {isModalOpen && (
                <div className="fixed z-50 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full z-50 relative">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                    {editing ? 'Edit Culture Cycle' : 'Add New Culture Cycle'}
                                </h3>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                        <label className="block text-gray-700">Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700">Culture Type</label>
                                        <input
                                            type="text"
                                            name="culture_type"
                                            value={formData.culture_type}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700">Start Date</label>
                                        <input
                                            type="date"
                                            name="start_date"
                                            value={formData.start_date}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700">Select Ponds</label>
                                        <Select
                                            isMulti
                                            name="ponds"
                                            options={pondOptions}
                                            value={pondOptions.filter(option => formData.ponds.includes(option.value))}
                                            onChange={(options) => handleMultiSelectChange(options, 'ponds')}
                                            className="basic-multi-select mt-1"
                                            classNamePrefix="select"
                                            styles={{
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700">Select Fish</label>
                                        <Select
                                            isMulti
                                            name="fish"
                                            options={fishOptions}
                                            value={fishOptions.filter(option => formData.fish.includes(option.value))}
                                            onChange={(options) => handleMultiSelectChange(options, 'fish')}
                                            className="basic-multi-select mt-1"
                                            classNamePrefix="select"

                                        />
                                    </div>
                                    <div className="flex justify-end">
                                        <button
                                            type="button"
                                            onClick={closeModal}
                                            className="bg-gray-500 text-white p-3 rounded mr-4 hover:bg-gray-600 transition duration-200"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-blue-500 text-white p-3 rounded hover:bg-blue-600 transition duration-200"
                                        >
                                            {editing ? 'Update Culture Cycle' : 'Add Culture Cycle'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CultureCycleManagement;
