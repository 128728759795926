import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from "react-select";
import {useTranslation} from "react-i18next";

const WaterQualityGraphAdmin = () => {
    const [farms, setFarms] = useState([]);
    const [selectedFarm, setSelectedFarm] = useState('');
    const [ponds, setPonds] = useState([]);
    const [selectedPond, setSelectedPond] = useState('');
    const [sensorData, setSensorData] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [selectedSensors, setSelectedSensors] = useState([]);
    const [loading, setLoading] = useState(false);
    const startOfcurrentDate = new Date();
    const endOfCurrentDate = new Date();
    startOfcurrentDate.setHours(0, 0, 0, 0);
    endOfCurrentDate.setHours(23, 59, 59, 999);
    const [startDate, setStartDate] = useState(startOfcurrentDate);
    const [endDate, setEndDate] = useState(endOfCurrentDate);
    const [pagination, setPagination] = useState({
        total: 0,
        currentPage: 1,
        totalPages: 1,
    });
    const { t } = useTranslation();
    const [pageSize] = useState(700); // You can adjust the number of items per page

    useEffect(() => {
        fetchFarms();
    }, []);

    const fetchPonds = async (farmId) => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/farm/${farmId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setPonds(response.data);
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Failed to fetch ponds',
                text: error.response?.data?.message || 'An error occurred while fetching ponds.',
            });
        });
    };

    const fetchFarms = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/farm/all/list`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setFarms(response.data.farms);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch farms.', 'error');
        }
    };

    const handleFarmSelect = (e) => {
        setSelectedPond('');
        setSensorData([]);
        setPonds([]);
        const farmId = e.target.value;
        setSelectedFarm(farmId);
        if(farmId){
            fetchPonds(farmId);
        }

    };

    const fetchSensorData = (pondId, sensorIds = null, page = 1,recurring = false) => {
        const token = localStorage.getItem('token');
        setLoading(true);
        let query = `?page=${page}&limit=${pageSize}`;
        if (startDate) query += `&startDate=${startDate.toISOString()}`;
        if (endDate) query += `&endDate=${endDate.toISOString()}`;
        if (sensorIds) query += `&sensor_ids=${sensorIds}`;

        axios
            .get(`${process.env.REACT_APP_BASE_URL}/api/pond/${pondId}/sensordata${query}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                if(recurring){
                    setSensorData((prevData) => [...prevData, ...response.data.data]);
                }
                else{
                    setSensorData(response.data.data);
                }

                setPagination({
                    total: response.data.pagination.total,
                    currentPage: response.data.pagination.currentPage,
                    totalPages: response.data.pagination.totalPages,
                });
                setLoading(false);
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to fetch sensor data',
                    text: error.response?.data?.message || 'An error occurred while fetching sensor data.',
                });
                setLoading(false);
            });
    };


    const fetchSensors = (pondId) => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/sensors/by-pond/${pondId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setSensors(response.data);
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Failed to fetch sensors',
                text: error.response?.data?.message || 'An error occurred while fetching sensors.',
            });
        });
    };

    const handlePondChange = (e) => {
        const pondId = e.target.value;
        setSelectedPond(pondId);
        if (pondId) {
            fetchSensors(pondId);
            setSelectedSensors([]);
            fetchSensorData(pondId);
        } else {
            setSensorData([]);
            setSensors([]);
            setSelectedSensors([]);
            setPagination({
                total: 0,
                currentPage: 1,
                totalPages: 1,
            });
        }
    };

    const handleSensorChange = (selectedOptions) => {
        setSelectedSensors(selectedOptions);
        if (selectedPond) {
            const sensorIds = selectedOptions.map(option => option.value); // Get sensor IDs from selected options
            fetchSensorData(selectedPond, sensorIds);
        }
    };


    const handleDateChange = () => {
        if (selectedPond) {
            const sensorIds = selectedSensors.map(option => option.value);
            fetchSensorData(selectedPond,sensorIds);
        }
    };


    const temperatureChartData = {
        labels: sensorData
            .filter((entry) => entry.sensor === 'Water Temp')
            .map((entry) => entry.time),
            // .reverse(),
        datasets: [
            {
                label: 'Temperature (°C)',
                data: sensorData
                    .filter((entry) => entry.sensor === 'Water Temp')
                    .map((entry) => entry.value),
                    // .reverse(),
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
                pointRadius: 2, // Sets the size of the point on the graph to 1px
                pointHoverRadius: 3,
            },
        ],
    };


    const pHChartData = {
        labels: [...new Set(sensorData.filter(entry => entry.sensor === 'Water PH').map(entry => entry.time))],
        datasets: [
            {
                label: 'pH Level',
                data: sensorData
                    .filter(entry => entry.sensor === 'Water PH')
                    .map(entry => entry.value),
                    // .reverse(),
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
                pointRadius: 2, // Sets the size of the point on the graph to 1px
                pointHoverRadius: 3,
            },
        ],
    };

    const dissolvedOxygenChartData = {
        labels: [...new Set(sensorData.filter(entry => entry.sensor === 'DO').map(entry => entry.time))],
        datasets: [
            {
                label: 'Dissolved Oxygen (mg/L)',
                data: sensorData
                    .filter(entry => entry.sensor === 'DO')
                    .map(entry => entry.value),
                    // .reverse(),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                pointRadius: 2, // Sets the size of the point on the graph to 1px
                pointHoverRadius: 3,
            },
        ],
    };

    const AMONIAChartData = {
        labels: [...new Set(sensorData.filter(entry => entry.sensor === 'Ammonia').map(entry => entry.time))],
        datasets: [
            {
                label: 'Ammonia (mg/L)',
                data: sensorData
                    .filter(entry => entry.sensor === 'Ammonia')
                    .map(entry => entry.value),
                    // .reverse(),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                pointRadius: 2, // Sets the size of the point on the graph to 1px
                pointHoverRadius: 3,
            },
        ],
    };

    const handleLoadNextPage = () => {
        if (!loading && pagination.currentPage < pagination.totalPages) {
            const nextPage = pagination.currentPage + 1;
            const sensorIds = selectedSensors.map((sensor) => sensor.value); // Assuming sensors are selected
            fetchSensorData(selectedPond, sensorIds, nextPage, true); // Adjust this call as per your fetch logic
        }
    };



    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">{t('pond_water_quality_data')}</h1>

            <div className="mb-8">
                <label htmlFor="farmSelect" className="block text-gray-700 mb-2">
                    {t('select_farm')}:
                </label>
                <select
                    id="farmSelect"
                    value={selectedFarm}
                    onChange={handleFarmSelect}
                    className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                >
                    <option value="">-- {t('select_farm')} --</option>
                    {farms.map((farm) => (
                        <option key={farm._id} value={farm._id}>
                            {farm.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">{t('select_pond')}</label>
                <select
                    value={selectedPond}
                    onChange={handlePondChange}
                    className="w-full p-3 border border-gray-300 rounded mt-1"
                >
                    <option value="">{t('select_pond')}</option>
                    {ponds.map((pond) => (
                        <option key={pond._id} value={pond._id}>
                            {pond.name}
                        </option>
                    ))}
                </select>
            </div>

            {sensors.length > 0 && (
                <div className="mb-4">
                    <label className="block text-gray-700">{t('select_parameter')}</label>
                    <Select
                        isMulti
                        options={sensors.map(sensor => ({
                            value: sensor._id,
                            label: sensor?.sensor_parameter_id?.sensor_name ?? ''
                        }))}
                        value={selectedSensors}
                        onChange={handleSensorChange}
                        className="w-full border border-gray-300 rounded mt-1"
                        classNamePrefix="react-select"
                        placeholder={t('select_parameter')}
                    />
                </div>
            )}

            <div className="mb-6 bg-white rounded-lg shadow-lg p-6 card">
                <div className="flex flex-col sm:flex-row items-center gap-4 sm:gap-6">
                    {/* Start Date Picker */}
                    <div className="w-full sm:w-1/2">
                        <label className="block text-gray-700 font-semibold mb-2">
                            {t('start_date')}
                        </label>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="Pp"
                            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                            placeholderText="Select start date and time"
                        />
                    </div>

                    {/* End Date Picker */}
                    <div className="w-full sm:w-1/2">
                        <label className="block text-gray-700 font-semibold mb-2">
                            {t('end_date')}
                        </label>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="Pp"
                            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                            placeholderText="Select end date and time"
                        />
                    </div>

                    {/* Filter Button */}
                    <div className="w-full sm:w-auto">
                        <button
                            onClick={handleDateChange}
                            className="w-full sm:w-auto bg-blue-500 hover:bg-blue-600 text-white font-semibold px-6 py-2 rounded-lg shadow-md transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
                        >
                            {t('filter')}
                        </button>
                    </div>
                </div>
            </div>


            {sensorData.length > 0 && (
                <div
                    className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-8 items-center justify-center mt-12 p-6 bg-gray-100 rounded-lg shadow-lg">
                    {temperatureChartData.labels.length > 0 && (<div
                        className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 mb-6 overflow-x-scroll"

                    >
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-semibold text-blue-600">Water Temperature (°C)</h2>
                            <button onClick={handleLoadNextPage}
                                    disabled={pagination.currentPage === pagination.totalPages}
                                    className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white transition duration-300 ${pagination.currentPage === pagination.totalPages ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'}`}>
                                <svg className="-mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                     fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                          clipRule="evenodd"/>
                                </svg>
                            </button>
                        </div>
                        <div className="h-[300px]">
                            <Line
                                data={temperatureChartData}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: {
                                        y: {
                                            min: 0,
                                            max: 50,
                                            title: {
                                                display: true,
                                                text: 'Temperature (°C)',
                                            },
                                        },
                                        x: {
                                            title: {
                                                display: true,
                                                text: 'Time',
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>)}

                    {pHChartData.labels.length > 0 && (<div
                        className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 mb-6 overflow-x-scroll"
                    >
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-semibold text-blue-600">pH Level</h2>
                            <button onClick={handleLoadNextPage}
                                    disabled={pagination.currentPage === pagination.totalPages}
                                    className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white transition duration-300 ${pagination.currentPage === pagination.totalPages ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'}`}>
                                <svg className="-mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                     fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                          clipRule="evenodd"/>
                                </svg>
                            </button>
                        </div>

                        <div className=" h-[300px]">
                            <Line
                                data={pHChartData}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: {
                                        y: {
                                            min: 0,
                                            max: 14,
                                            title: {
                                                display: true,
                                                text: 'pH Level',
                                            },
                                        },
                                        x: {
                                            title: {
                                                display: true,
                                                text: 'Time',
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>)}

                    {dissolvedOxygenChartData.labels.length > 0 && (<div
                        className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 mb-6 overflow-x-scroll"
                    >
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-semibold text-blue-600">Dissolved Oxygen (mg/L)</h2>
                            <button onClick={handleLoadNextPage}
                                    disabled={pagination.currentPage === pagination.totalPages}
                                    className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white transition duration-300 ${pagination.currentPage === pagination.totalPages ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'}`}>
                                <svg className="-mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                     fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                          clipRule="evenodd"/>
                                </svg>
                            </button>
                        </div>
                        <div className="h-[300px]">
                            <Line
                                data={dissolvedOxygenChartData}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: {
                                        y: {
                                            min: 0,
                                            max: 15,
                                            title: {
                                                display: true,
                                                text: 'Dissolved Oxygen (mg/L)',
                                            },
                                        },
                                        x: {
                                            title: {
                                                display: true,
                                                text: 'Time',
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>)}
                    {AMONIAChartData.labels.length > 0 && (<div
                        className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 mb-6 overflow-x-scroll"
                    >
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-semibold text-blue-600">Ammonia (mg/L)</h2>
                            <button onClick={handleLoadNextPage}
                                    disabled={pagination.currentPage === pagination.totalPages}
                                    className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white transition duration-300 ${pagination.currentPage === pagination.totalPages ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'}`}>
                                <svg className="-mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                     fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                          clipRule="evenodd"/>
                                </svg>
                            </button>
                        </div>
                        <div className="h-[300px]">
                            <Line
                                data={AMONIAChartData}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: {
                                        y: {
                                            min: 0,
                                            max: 20,
                                            title: {
                                                display: true,
                                                text: 'Ammonia (mg/L)',
                                            },
                                        },
                                        x: {
                                            title: {
                                                display: true,
                                                text: 'Time',
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>)}
                </div>


            )}

        </div>
    );
};

export default WaterQualityGraphAdmin;
