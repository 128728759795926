import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from "react-select";
import {useTranslation} from "react-i18next";

const PondSensorAlertPage = () => {
    const [ponds, setPonds] = useState([]);
    const [selectedPond, setSelectedPond] = useState('');
    const [sensorData, setSensorData] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [selectedSensors, setSelectedSensors] = useState([]);
    const [insights, setInsights] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [status, setStatus] = useState('');
    const { t } = useTranslation();
    const [pagination, setPagination] = useState({
        total: 0,
        currentPage: 1,
        totalPages: 1,
    });
    const [pageSize] = useState(10);

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/all`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setPonds(response.data);
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Failed to fetch ponds',
                text: error.response?.data?.message || 'An error occurred while fetching ponds.',
            });
        });
    }, []);

    const fetchSensorData = (pondId, sensorIds = null, page = 1) => {
        const token = localStorage.getItem('token');
        setLoading(true);
        let query = `?page=${page}&limit=${pageSize}`;
        if (startDate) query += `&startDate=${startDate.toISOString()}`;
        if (endDate) query += `&endDate=${endDate.toISOString()}`;
        if (sensorIds) query += `&sensor_ids=${sensorIds}`;
        if (status) query += `&status=${status}`;

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/${pondId}/sensoralert${query}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            const data = response.data.data;
            setSensorData(data);
            setPagination({
                total: response.data.pagination.total,
                currentPage: response.data.pagination.currentPage,
                totalPages: response.data.pagination.totalPages,
            });
            setLoading(false);

            // Calculate sensor-wise insights
            const insights = data.reduce((acc, entry) => {
                const { sensor, value, status, time } = entry;

                if (!acc[sensor]) {
                    acc[sensor] = {
                        min: value,
                        max: value,
                        total: value,
                        count: 1,
                        statusCount: { [status]: 1 },
                        startTime: time,
                        endTime: time
                    };
                } else {
                    acc[sensor].min = Math.min(acc[sensor].min, value);
                    acc[sensor].max = Math.max(acc[sensor].max, value);
                    acc[sensor].total += value;
                    acc[sensor].count += 1;

                    // Count status occurrences
                    acc[sensor].statusCount[status] = (acc[sensor].statusCount[status] || 0) + 1;

                    // Update start and end time for the time range
                    acc[sensor].startTime = acc[sensor].startTime < time ? acc[sensor].startTime : time;
                    acc[sensor].endTime = acc[sensor].endTime > time ? acc[sensor].endTime : time;
                }
                return acc;
            }, {});

// Convert insights to an array with average calculation and additional data
            const insightsArray = Object.keys(insights).map(sensor => ({
                sensor,
                min: insights[sensor].min,
                max: insights[sensor].max,
                avg: (insights[sensor].total / insights[sensor].count).toFixed(2),
                statusCount: insights[sensor].statusCount,
                timeRange: {
                    from: insights[sensor].startTime,
                    to: insights[sensor].endTime
                }
            }));

            setInsights(insightsArray);
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Failed to fetch sensor data',
                text: error.response?.data?.message || 'An error occurred while fetching sensor data.',
            });
            setLoading(false);
        });
    };

    const fetchSensors = (pondId) => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/sensors/by-pond/${pondId}`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
            setSensors(response.data);
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Failed to fetch sensors',
                text: error.response?.data?.message || 'An error occurred while fetching sensors.',
            });
        });
    };

    const handlePondChange = (e) => {
        const pondId = e.target.value;
        setSelectedPond(pondId);
        if (pondId) {
            fetchSensors(pondId);
            setSelectedSensors([]);
            fetchSensorData(pondId);
        } else {
            setSensorData([]);
            setSensors([]);
            setSelectedSensors([]);
            setPagination({
                total: 0,
                currentPage: 1,
                totalPages: 1,
            });
        }
    };

    const handleSensorChange = (selectedOptions) => {
        setSelectedSensors(selectedOptions);
        if (selectedPond) {
            const sensorIds = selectedOptions.map(option => option.value);
            fetchSensorData(selectedPond, sensorIds);
        }
    };

    const handleDateChange = () => {
        if (selectedPond) {
            const sensorIds = selectedSensors.map(option => option.value);
            fetchSensorData(selectedPond, sensorIds);
        }
    };

    const getPaginationButtons = () => {
        const { currentPage, totalPages } = pagination;
        const pageNumbers = [];
        let startPage = Math.max(currentPage - 2, 1);
        let endPage = Math.min(currentPage + 2, totalPages);

        if (startPage > 1) {
            pageNumbers.push(1);
            if (startPage > 2) pageNumbers.push('...');
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        if (endPage < totalPages - 1) pageNumbers.push('...');
        if (endPage < totalPages) pageNumbers.push(totalPages);

        return pageNumbers;
    };

    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= pagination.totalPages) {
            const sensorIds = selectedSensors.map(option => option.value);
            fetchSensorData(selectedPond, sensorIds, pageNumber);
        }
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">{t('pond_water_quality_alerts')}</h1>

            {/* Pond and Sensor Selection */}
            <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="flex flex-col">
                    <label className="block text-gray-700">{t('select_pond')}</label>
                    <select value={selectedPond} onChange={handlePondChange}
                            className="w-full border border-gray-300 rounded mt-1 p-3">
                        <option value="">{t('select_pond')}</option>
                        {ponds.map((pond) => (
                            <option key={pond._id} value={pond._id}>{pond.name}</option>
                        ))}
                    </select>
                </div>
                {sensors.length > 0 && (
                    <div className="flex flex-col">
                        <label className="block text-gray-700">{t('select_parameter')}</label>
                        <Select
                            isMulti
                            options={sensors.map(sensor => ({
                                value: sensor._id,
                                label: sensor?.sensor_parameter_id?.sensor_name ?? ''
                            }))}
                            value={selectedSensors}
                            onChange={handleSensorChange}
                            className="w-full border border-gray-300 rounded mt-1 p-1"
                            classNamePrefix="react-select"
                            placeholder={t('select_parameter')}
                        />
                    </div>
                )}

            </div>

            <div className="card rounded bg-cyan-200 p-4 mb-10">
                <h4 className="text-xl font-bold mb-6">{t('filter')}</h4>
                <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="flex flex-col">
                        <label className="text-gray-600 font-semibold mb-2">{t('start_date_time')}</label>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            dateFormat="Pp"
                            className="w-full px-4 py-3 border border-gray-300 rounded-lg"
                            placeholderText={t('start_date_time')}
                        />
                    </div>
                    <div className="flex flex-col">
                        <label className="text-gray-600 font-semibold mb-2">{t('end_date_time')}</label>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            showTimeSelect
                            dateFormat="Pp"
                            className="w-full px-4 py-3 border border-gray-300 rounded-lg"
                            placeholderText={t('end_date_time')}
                        />
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-gray-700">{t('status')}</label>
                        <select value={status} onChange={(event) => setStatus(event.target.value)}
                                className="w-full p-3 border border-gray-300 rounded mt-1">
                            <option value="">{t('select_status')}</option>
                            <option value="danger">{t('danger')}</option>
                            <option value="warning">{t('warning')}</option>
                        </select>
                    </div>
                </div>
                <button onClick={handleDateChange} className="bg-blue-500 text-white px-4 py-2 rounded mb-6">
                    {t('filter')}
                </button>
            </div>

            {insights.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {insights.map((insight, index) => (
                        <div
                            key={index}
                            className="bg-white p-5 rounded-lg shadow-xl border border-gray-200 hover:shadow-2xl transition-transform duration-300 hover:scale-105 flex flex-col"
                        >
                            {/* Sensor Name */}
                            <h3 className="text-lg font-semibold text-blue-700 mb-4 text-center">{insight.sensor}</h3>

                            <div className="flex flex-col">
                                {/* Min, Max, Average */}
                                <div className="flex justify-between card rounded bg-blue-50 p-3 mb-3">
                                    <div className="flex justify-between text-xl">
                                        <span className="text-gray-500 font-medium">{t('min')} : </span>
                                        <span className="font-semibold text-green-500"> {insight.min}</span>
                                    </div>
                                    <div className="flex justify-between text-xl">
                                        <span className="text-gray-500 font-medium">{t('max')} : </span>
                                        <span className="font-semibold text-red-500"> {insight.max}</span>
                                    </div>
                                    <div className="flex justify-between text-xl">
                                        <span className="text-gray-500 font-medium">{t('avg')} : </span>
                                        <span className="font-semibold text-yellow-500"> {insight.avg}</span>
                                    </div>
                                </div>

                                {/* Status Counts and Time Range */}
                                <div className="space-y-3">
                                    {/* Status Counts */}
                                    <div className="flex justify-between items-center text-center card rounded p-2">
                                        {/*<h4 className="text-gray-600 font-semibold text-sm mb-2">Status Counts</h4>*/}
                                        <div className="flex flex-wrap gap-2">
                                            {Object.entries(insight.statusCount).map(([status, count]) => (
                                                <span
                                                    key={status}
                                                    className={`px-3 py-1 text-xs rounded-lg font-medium ${
                                                        status === 'danger'
                                                            ? 'bg-red-200 text-red-700'
                                                            : 'bg-orange-300 text-yellow-900'
                                                    }`}
                                                >
                                                        {status.charAt(0).toUpperCase() + status.slice(1)} : {count}
                                                </span>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Time Range */}
                                    <div className="text-gray-600 flex justify-between card rounded p-3 bg-cyan-50">
                                        <p>
                                            <span className="font-xl font-medium">{t('from')}:</span> {insight.timeRange.from}
                                        </p>
                                        <p>
                                            <span className="font-xl font-medium">{t('to')}:</span> {insight.timeRange.to}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>


            ) : (
                <p className="text-gray-600 text-center mt-8">No insights available. Please select a pond and
                    sensors to view insights.</p>
            )}

            {/* Pagination Controls */}
            {pagination.total > 0 && (
                <div className="mt-10 flex justify-center items-center space-x-2">
                    <button
                        onClick={() => handlePageChange(1)}
                        className={`px-3 py-1 rounded ${pagination.currentPage === 1 ? 'bg-gray-300' : 'bg-gray-200 text-gray-800'}`}
                        disabled={pagination.currentPage === 1}
                    >
                        {t('first')}
                    </button>
                    <button
                        onClick={() => handlePageChange(pagination.currentPage - 1)}
                        className={`px-3 py-1 rounded ${pagination.currentPage === 1 ? 'bg-gray-300' : 'bg-gray-200 text-gray-800'}`}
                        disabled={pagination.currentPage === 1}
                    >
                        {t('previous')}
                    </button>
                    {getPaginationButtons().map((page, index) =>
                        page === '...' ? (
                            <span key={index} className="px-3 py-1">...</span>
                        ) : (
                            <button
                                key={index}
                                onClick={() => handlePageChange(page)}
                                className={`px-3 py-1 rounded ${
                                    pagination.currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
                                }`}
                            >
                                {page}
                            </button>
                        )
                    )}
                    <button
                        onClick={() => handlePageChange(pagination.currentPage + 1)}
                        className={`px-3 py-1 rounded ${pagination.currentPage === pagination.totalPages ? 'bg-gray-300' : 'bg-gray-200 text-gray-800'}`}
                        disabled={pagination.currentPage === pagination.totalPages}
                    >
                        {t('next')}
                    </button>
                    <button
                        onClick={() => handlePageChange(pagination.totalPages)}
                        className={`px-3 py-1 rounded ${pagination.currentPage === pagination.totalPages ? 'bg-gray-300' : 'bg-gray-200 text-gray-800'}`}
                        disabled={pagination.currentPage === pagination.totalPages}
                    >
                        {t('last')}
                    </button>
                </div>
            )}


            {/* Loading Indicator */}
            {loading ? (
                <div className="flex items-center justify-center">
                    <div className="flex items-center space-x-2 animate-pulse">
                        <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                        <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                        <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                        <div className="text-blue-500 text-lg font-semibold">Loading...</div>
                    </div>
                </div>
            ) : (
                <>
                    {/* Sensor Data Table */}
                    <div className="overflow-x-auto mt-8">
                        <table className="min-w-full bg-white border">
                            <thead>
                            <tr>
                                <th className="px-4 py-2 border">{t('pond')}</th>
                                <th className="px-4 py-2 border">{t('parameter')}</th>
                                <th className="px-4 py-2 border">{t('value')}</th>
                                <th className="px-4 py-2 border">{t('min')}</th>
                                <th className="px-4 py-2 border">{t('max')}</th>
                                <th className="px-4 py-2 border">{t('status')}</th>
                                <th className="px-4 py-2 border">{t('time')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {sensorData.map((entry, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-2 border text-center">{entry.pond}</td>
                                    <td className="px-4 py-2 border text-center">{entry.sensor}</td>
                                    <td className="px-4 py-2 border text-center">{entry.value} {entry.unit}</td>
                                    <td className="px-4 py-2 border text-center">{entry.min} {entry.unit}</td>
                                    <td className="px-4 py-2 border text-center">{entry.max} {entry.unit}</td>
                                    <td className="px-4 py-2 border text-center">{entry.status && entry.status === 'danger' ? t('danger') : t('warning')}</td>
                                    <td className="px-4 py-2 border text-center">{entry.time}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
};

export default PondSensorAlertPage;
