import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '@sweetalert2/theme-dark/dark.min.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWater} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

function PondManagement() {
    const [ponds, setPonds] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        pond_shape: '',
        pond_meta: {},
        length: '',
        width: '',
        depth: '',
        circumference: '',
        pond_type: '',
        pond_classification: '',
        pond_image: '',
        pond_size: '', // Automatically calculated
    });
    const [editing, setEditing] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        fetchPonds();
    }, []);

    useEffect(() => {
        const calculatePondSize = () => {
            let size = 0;
            let meta = {};

            if (formData.pond_shape === 'rectangle' && formData.length && formData.width && formData.depth) {
                size = formData.length * formData.width * formData.depth;
                meta = {
                    pond_shape: formData.pond_shape,
                    length: formData.length,
                    width: formData.width,
                    depth: formData.depth
                }
            } else if (formData.pond_shape === 'rounded' && formData.circumference && formData.depth) {
                const radius = formData.circumference / (2 * Math.PI);
                size = Math.PI * radius * radius * formData.depth ;
                meta = {
                    pond_shape: formData.pond_shape,
                    circumference: formData.circumference,
                    depth: formData.depth
                }
            }

            setFormData((prevData) => ({
                ...prevData,
                pond_meta: meta,
                pond_size: size.toFixed(2), // Round to 2 decimal places
            }));
        };

        calculatePondSize();
    },[formData.pond_shape, formData.length, formData.depth, formData.width,formData.circumference]);

    const fetchPonds = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPonds(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch ponds.', 'error');
        }
    };

    const handleChange = (e) => {
        if (e.target.name === "pond_image") {
            const file = e.target.files[0]; // Get the first selected file
            setFormData((prev) => ({
                ...prev,
                pond_image: file, // Store the file object in formData
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        try {
            if (editing) {
                await axios.put(`${process.env.REACT_APP_BASE_URL}/api/pond/${editing._id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                });
                Swal.fire('Success', 'Pond updated successfully', 'success');
            } else {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/api/pond/create`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                });
                Swal.fire('Success', 'Pond created successfully', 'success');
            }
            setFormData({
                name: '',
                pond_shape: '',
                pond_meta: {},
                length: '',
                width: '',
                depth: '',
                circumference: '',
                pond_type: '',
                pond_classification: '',
                pond_image: '',
                pond_size: '',
            });
            setEditing(null);
            setIsModalOpen(false);
            fetchPonds();
        } catch (error) {
            Swal.fire('Error', 'Failed to save pond.', 'error');
        }
    };

    const handleEdit = (pond) => {
        setFormData({
            name: pond.name,
            pond_shape: pond.pond_meta?.pond_shape,
            length: pond.pond_meta?.length || 0,
            width: pond.pond_meta?.width || 0,
            depth: pond.pond_meta?.depth || 0,
            circumference: pond.pond_meta?.circumference || 0,
            pond_type: pond.pond_type,
            pond_classification: pond.pond_classification,
            pond_image: pond.pond_image,
            pond_size: pond.pond_size,
        });
        setEditing(pond);
        setIsModalOpen(true);
    };

    const handleDelete = async (id) => {
        const token = localStorage.getItem('token');
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to delete this pond?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!',
            });

            if (result.isConfirmed) {
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/pond/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                Swal.fire('Deleted!', 'Pond has been deleted.', 'success');
                fetchPonds();
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to delete pond.', 'error');
        }
    };

    const openModal = () => {
        setFormData({
            name: '',
            pond_shape: '',
            length: '',
            width: '',
            depth: '',
            circumference: '',
            pond_type: '',
            pond_classification: '',
            pond_image: '',
            pond_size: '',
        });
        setEditing(null);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">{t('pond_management')}</h1>
            <button
                onClick={openModal}
                className="bg-blue-500 text-white p-3 rounded mb-6 hover:bg-blue-600 transition duration-200"
            >
                {t('add_new_pond')}
            </button>

            <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-2xl font-semibold mb-4">{t('pond_list')}</h2>
                {ponds.length > 0 ? (
                    <table className="min-w-full bg-white">
                        <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">{t('name')}</th>
                            <th className="py-2 px-4 border-b">{t('pond_shape')}</th>
                            <th className="py-2 px-4 border-b">{t('pond_size')}</th>
                            <th className="py-2 px-4 border-b">{t('pond_type')}</th>
                            <th className="py-2 px-4 border-b">{t('pond_image')}</th>
                            <th className="py-2 px-4 border-b">{t('action')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ponds.map((pond) => (
                            <tr key={pond._id}>
                                <td className="py-2 px-4 border-b text-center">{pond.name}</td>
                                <td className="py-2 px-4 border-b text-center">{pond.pond_meta?.pond_shape}</td>
                                <td className="py-2 px-4 border-b text-center">{pond.pond_size}</td>
                                <td className="py-2 px-4 border-b text-center">{pond.pond_type}</td>
                                <td className="py-2 px-4 border-b flex items-center justify-center">
                                    <div className="relative">
                                        {pond.pond_image ? (
                                            <img
                                                src={`${process.env.REACT_APP_BASE_URL}/${pond.pond_image}`}
                                                className="w-12 h-12 object-cover rounded"
                                                alt={'pond'}/>
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faWater}
                                                className="w-12 h-12 text-gray-500"
                                            />
                                        )}
                                    </div>
                                </td>
                                <td className="py-2 px-4 border-b text-center">
                                    <button
                                        onClick={() => handleEdit(pond)}
                                        className="bg-yellow-500 text-white px-4 py-2 rounded mr-2 hover:bg-yellow-600"
                                    >
                                        {t('edit')}
                                    </button>
                                    <button
                                        onClick={() => handleDelete(pond._id)}
                                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 hidden"
                                    >
                                        {t('delete')}
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ) : (
                    <p>{t('no_ponds_found')}</p>
                )}
            </div>

            {isModalOpen && (
                <div className="fixed z-50 inset-0 overflow-y-auto">
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"
                              aria-hidden="true">&#8203;</span>
                        <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                    {editing ? 'Edit Pond' : 'Add New Pond'}
                                </h3>
                                <form onSubmit={handleSubmit} className="space-y-4">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div className="mb-4">
                                            <label className="block text-gray-700 mb-2">{t('name')}</label>
                                            <input
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                className="w-full p-2 border border-gray-900 rounded"
                                                required
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-gray-700 mb-2">{t('pond_shape')}</label>
                                            <select
                                                name="pond_shape"
                                                value={formData.pond_shape}
                                                onChange={handleChange}
                                                className="w-full p-2 border border-gray-900 rounded"
                                                required
                                            >
                                                <option value="">{t('select_pond_shape')}</option>
                                                <option value="rounded">{t('rounded')}</option>
                                                <option value="rectangle">{t('rectangle')}</option>
                                            </select>
                                        </div>

                                        {formData.pond_shape === 'rectangle' && (
                                            <>
                                                <div className="mb-4">
                                                    <label className="block text-gray-700 mb-2">{t('length')}</label>
                                                    <input
                                                        type="number"
                                                        name="length"
                                                        value={formData.length || ''}
                                                        onChange={handleChange}
                                                        className="w-full p-2 border border-gray-900 rounded"
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="block text-gray-700 mb-2">{t('width')}</label>
                                                    <input
                                                        type="number"
                                                        name="width"
                                                        value={formData.width || ''}
                                                        onChange={handleChange}
                                                        className="w-full p-2 border border-gray-900 rounded"
                                                        required
                                                    />
                                                </div>
                                            </>
                                        )}

                                        {formData.pond_shape === 'rounded' && (
                                            <div className="mb-4">
                                                <label className="block text-gray-700 mb-2">{t('circumference')}</label>
                                                <input
                                                    type="number"
                                                    name="circumference"
                                                    value={formData.circumference || ''}
                                                    onChange={handleChange}
                                                    className="w-full p-2 border border-gray-900 rounded"
                                                    required
                                                />
                                            </div>
                                        )}

                                        <div className="mb-4">
                                            <label className="block text-gray-700 mb-2">{t('select')} {t('depth')}</label>
                                            <input
                                                type="number"
                                                name="depth"
                                                value={formData.depth || ''}
                                                onChange={handleChange}
                                                className="w-full p-2 border border-gray-900 rounded"
                                                required
                                            />
                                        </div>

                                        <div className="mb-4">
                                            <label className="block text-gray-700 mb-2">{t('pond_type')}</label>
                                            <select
                                                name="pond_type"
                                                value={formData.pond_type}
                                                onChange={handleChange}
                                                className="w-full p-2 border border-gray-900 rounded"
                                                required
                                            >
                                                <option value="">{t('pond_type')}</option>
                                                <option value="culture">{t('culture')}</option>
                                                <option value="nursery">{t('nursery')}</option>
                                                <option value="service">{t('service')}</option>
                                            </select>
                                        </div>

                                        <div className="mb-4">
                                            <label className="block text-gray-700 mb-2">{t('pond_classification')}</label>
                                            <select
                                                name="pond_classification"
                                                value={formData.pond_classification}
                                                onChange={handleChange}
                                                className="w-full p-2 border border-gray-900 rounded"
                                                required
                                            >
                                                <option value="">{t('select')} {t('pond_classification')}</option>
                                                <option value="ordinary">{t('ordinary')}</option>
                                                <option value="contraction">{t('contraction')}</option>
                                                <option value="r&d">{t('r&d')}</option>
                                            </select>
                                        </div>

                                        <div className="mb-4">
                                            <label className="block text-gray-700 mb-2">{t('pond_size')}</label>
                                            <input
                                                type="text"
                                                name="pond_size"
                                                value={formData.pond_size}
                                                readOnly
                                                className="w-full p-2 border border-gray-900 rounded bg-gray-100"
                                            />
                                        </div>

                                        <div className="mb-4">
                                            <label className="block text-gray-700 mb-2">{t('pond_image')}</label>
                                            {formData.pond_image && typeof formData.pond_image === 'string' && (
                                                <img src={`${process.env.REACT_APP_BASE_URL}/${formData.pond_image}`}
                                                     alt="Pond" className="mb-2 w-full h-auto rounded"/>
                                            )}
                                            <input
                                                type="file"
                                                name="pond_image"
                                                onChange={handleChange}
                                                className="w-full p-2 border border-gray-900 rounded"
                                            />
                                        </div>


                                    </div>

                                    <div className="flex justify-end">
                                        <button
                                            type="button"
                                            onClick={closeModal}
                                            className="bg-gray-500 text-white p-3 rounded mr-4 hover:bg-gray-600 transition duration-200"
                                        >
                                            {t('cancel')}
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-blue-500 text-white p-3 rounded hover:bg-blue-600 transition duration-200"
                                        >
                                            {editing ? t('update_pond') : t('add_pond')}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PondManagement;
