import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBuilding,
    faTachometerAlt,
    faTractor,
    faWater,
    faUsers,
    faCogs,
    faTable,
    faTemperature0,
    faRandom,
    faFish,
    faNetworkWired,
    faMemory,
    faSnowplow,
    faRetweet, faClockRotateLeft, faHouseFloodWater, faBell, faStopwatch, faChartColumn, faUserPen
} from '@fortawesome/free-solid-svg-icons'; // Import the faUsers icon
import { NavLink } from 'react-router-dom';
import {UserContext} from "./services/UserContext";
import {useTranslation} from "react-i18next";


const Sidebar = ({ isSidebarOpen }) => {
    const { userRoles } = useContext(UserContext);
    const { t } = useTranslation();

    return (
        <div
            className={`bg-sky-800 text-white h-screen flex flex-col fixed top-0 left-0 transform transition-transform duration-300 ease-in-out item-center ${
                isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
            style={{width: '16rem'}} // Equivalent to w-64 in Tailwind
        >
            <h1 className="text-lg font-bold p-4 flex items-center"><img src="/favicon.ico" alt="Logo" className="h-12 w-12 mx-4 my-2 rounded"/> {t('app_name')}</h1>

            <ul className="flex-1">
            <li>
                <NavLink
                        to="/"
                        className={({isActive}) =>
                            `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                        }
                    >
                        <FontAwesomeIcon icon={faTachometerAlt} className="mr-2"/>
                    {t('dashboard')}
                    </NavLink>
                </li>

                {userRoles.includes('super_admin') && (
                    <>
                        <li>
                            <NavLink
                                to="/companies"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faBuilding} className="mr-2"/>
                                Company
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/farms"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faHouseFloodWater} className="mr-2"/>
                                Farm
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/ponds"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faWater} className="mr-2"/>
                                Pond
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/fish-manage"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faFish}
                                                 className="mr-2"/> {/* Assuming faCogs for sensor management icon */}
                                Fish Management
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/sensor-manage"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faCogs}
                                                 className="mr-2"/> {/* Assuming faCogs for sensor management icon */}
                                Sensor Management
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/device-manage"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faMemory}
                                                 className="mr-2"/> {/* Assuming faCogs for sensor management icon */}
                                Device Management
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/conductor-manage"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faNetworkWired}
                                                 className="mr-2"/> {/* Assuming faCogs for sensor management icon */}
                                Conductor Management
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/device-interval"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faStopwatch}
                                                 className="mr-2"/> {/* Assuming faCogs for sensor management icon */}
                                Device Interval
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/peripheral-manage"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faSnowplow}
                                                 className="mr-2"/> {/* Assuming faCogs for sensor management icon */}
                                Peripheral Management
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/peripheral-threshold-manage"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faRetweet}
                                                 className="mr-2"/> {/* Assuming faCogs for sensor management icon */}
                                Peripheral Threshold Management
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/data-tempering"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faUserPen}
                                                 className="mr-2"/> {/* Assuming faCogs for sensor management icon */}
                                Data Tempering
                            </NavLink>
                        </li>
                    </>
                )}

                {userRoles.includes('admin') && (
                    <>
                        <li>
                            <NavLink
                                to="/farm"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faTractor} className="mr-2"/>
                                {t('farm')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/users"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faUsers} className="mr-2"/>
                                {t('user')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/farm-pond-status"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faWater} className="mr-2"/>
                                {t('farm_pond')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/water-quality-graph"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faChartColumn} className="mr-2"/>
                                {t('water_quality')}
                            </NavLink>
                        </li>
                    </>

                )}

                {userRoles.includes('ngo_admin') && (
                    <>
                        <li>
                            <NavLink
                                to="/ngo-farm-pond-status"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faWater} className="mr-2"/>
                                {t('farm_pond')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/water-quality-graph-ngo"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faChartColumn} className="mr-2"/>
                                {t('water_quality')}
                            </NavLink>
                        </li>

                    </>

                )}

                {userRoles.includes('farmer') && (
                    <>
                        <li>
                            <NavLink
                                to="/pond"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faWater} className="mr-2"/>
                                {t('pond')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/pond-sensor-data"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faTable} className="mr-2"/>
                                {t('pond_sensor_data')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/pond-status"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faTemperature0} className="mr-2"/>
                                {t('pond_status')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/sensor-thresholds"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faRandom} className="mr-2"/>
                                {t('sensor_thresholds')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/sensor-alert"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faBell} className="mr-2"/>
                                {t('sensor_alert')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/culture-cycle"
                                className={({isActive}) =>
                                    `p-4 shadow-blue-950 shadow flex items-center hover:bg-sky-700 ${isActive ? 'bg-sky-700' : ''}`
                                }
                            >
                                <FontAwesomeIcon icon={faClockRotateLeft} className="mr-2"/>
                                {t('culture_cycle')}
                            </NavLink>
                        </li>
                    </>

                )}
            </ul>
        </div>
    );
}

export default Sidebar;
