import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import GaugeComponent from "../../components/GaugeComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRulerCombined,
    faShapes,
    faArrowsAltV,
    faLayerGroup,
    faFish,
    faCircleArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import ToggleSwitch from '../../components/ToggleSwitch'; // Updated Toggle Switch
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const PondDetailsPage = () => {
    const { pondId } = useParams();
    const [pond, setPond] = useState(null);
    const [sensorThresholds, setSensorThresholds] = useState([]);
    const [peripherals, setPeripherals] = useState([]); // New state for peripherals
    const [loading, setLoading] = useState(true);
    const [toggleLoading, setToggleLoading] = useState({}); // Loading state for each toggle
    const [sensorData, setSensorData] = useState([]);
    const colors = ['#9895ec', '#6c9dea', '#e49d69', '#83e8d8']; // Array of colors
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/sensor-thresholds/pond/${pondId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setPond(response.data.pond);
                setSensorThresholds(response.data.sensors);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                const message = error.response?.status === 203
                    ? 'No data found for this pond.'
                    : error.response?.status === 404
                        ? 'The specified pond could not be found.'
                        : 'An error occurred while fetching sensor threshold data.';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: message,
                });
            });

        // Fetch peripherals for this pond
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/peripherals/pond/${pondId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setPeripherals(response.data);
            })
            .catch((error) => {
                const message = error.response?.status === 404
                    ? 'No peripherals found for this pond.'
                    : 'An error occurred while fetching peripherals.';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: message,
                });
            });

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/sensor-24-summery/${pondId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (response.data.success) {
                    setSensorData(response.data.data.sensors);
                } else {
                    Swal.fire('Error', response.data.message, 'error');
                }
            })
            .catch((error) => {
                const message = error.response?.status === 404
                    ? 'No sensor data found for this pond.'
                    : 'An error occurred while fetching sensors data.';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: message,
                });
            });

    }, [pondId]);

    const handleOnOff = async (peripheralId, action) => {
        const token = localStorage.getItem('token');
        setToggleLoading((prev) => ({ ...prev, [peripheralId]: true })); // Set loading for this specific peripheral
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/peripherals/control`, {
                action, // 'on' or 'off'
                peripheralId
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            Swal.fire('Success', `Peripheral turned ${action}`, 'success');
            // Fetch the updated peripherals to reflect the new status
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/peripherals/pond/${pondId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setPeripherals(response.data);
        } catch (error) {
            Swal.fire('Error', `Failed to turn ${action} peripheral.`, 'error');
        } finally {
            setToggleLoading((prev) => ({ ...prev, [peripheralId]: false })); // Stop loading for this specific peripheral
        }
    };

    if (loading) {
        return <div className="flex items-center justify-center mt-20">
            <div className="flex items-center space-x-2 animate-pulse">
                <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                <div className="text-blue-500 text-lg font-semibold">Loading...</div>
            </div>
        </div>;
    }

    return (
        <div className="container mx-auto p-6">
            <button
                onClick={() => navigate(-1)}
                className="mb-8 w-14 h-14 bg-gradient-to-r from-sky-500 to-sky-500 text-white font-semibold rounded-full shadow-md hover:from-sky-800 hover:to-sky-500 transition duration-200 transform hover:scale-110 flex items-center justify-center"
            >
                <FontAwesomeIcon icon={faCircleArrowLeft} className="text-2xl"/>
            </button>

            <h1 className="text-4xl font-bold text-center mb-10 text-blue-700">{pond?.name} - {t('details')}</h1>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                <div className="lg:col-span-2">
                    <h2 className="text-3xl font-semibold mb-6 text-center">{t('water_quality')}</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                        {sensorThresholds.length > 0 ? (
                            sensorThresholds.map((threshold, index) => (
                                <div
                                    key={index}
                                    className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
                                >
                                    <div className="bg-blue-600 text-white rounded-t-lg p-4 text-center">
                                        <h3 className="text-xl font-bold">{threshold.sensor} </h3>
                                        <h6 className="text-xl font-bold">({threshold.updated_at})</h6>
                                    </div>
                                    <div className="p-4">
                                        <GaugeComponent
                                            value={threshold.value}
                                            minValue={threshold.sensor_min}
                                            maxValue={threshold.sensor_max}
                                            threshold={{min: threshold.min, max: threshold.max}}
                                        />
                                    </div>
                                    <div className="text-center mt-4">
                                        <p className="text-sm text-gray-600">Safe Range: <span
                                            className="font-semibold">{threshold.min}</span> - <span
                                            className="font-semibold">{threshold.max}</span></p>
                                        <p className="text-sm text-gray-600">Sensor Range: <span
                                            className="font-semibold">{threshold.sensor_min}</span> - <span
                                            className="font-semibold">{threshold.sensor_max}</span></p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-center text-gray-600">No sensor thresholds found for this pond.</p>
                        )}
                    </div>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                    <h2 className="text-2xl font-semibold mb-6 text-center">{t('pond_information')}</h2>
                    <div className="space-y-4">
                        <PondInfo icon={faRulerCombined} label={t('size')} value={`${pond?.pond_size} sqm`}/>
                        <PondInfo icon={faLayerGroup} label={t('type')} value={pond?.pond_type}/>
                        <PondInfo icon={faFish} label={t('classification')} value={pond?.pond_classification}/>
                        <PondInfo icon={faShapes} label={t('shape')} value={pond?.pond_meta?.pond_shape}/>
                        <PondInfo icon={faArrowsAltV} label={t('depth')} value={`${pond?.pond_meta?.depth} m`}/>
                        {pond?.pond_image && (
                            <div className="mt-6">
                                <strong>{t('image')}:</strong>
                                <img src={`${process.env.REACT_APP_BASE_URL}/${pond.pond_image}`} alt="Pond"
                                     className="w-full h-auto mt-2 rounded-lg shadow-md"/>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Enhanced Pond Peripherals Section */}
            <div
                className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 mt-10 hidden">
                <h2 className="text-3xl font-semibold mb-6 text-center">Pond Peripherals</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {peripherals.length > 0 ? (
                        peripherals.map((peripheral, index) => (
                            <div key={index}
                                 className="relative bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
                                <h3 className="text-xl font-bold text-center text-gray-800 mb-6">{peripheral.name}</h3>
                                {/* Toggle Switch Button */}
                                <div className="flex justify-center">
                                    <ToggleSwitch
                                        isOn={peripheral.status === 'on'}
                                        isLoading={toggleLoading[peripheral._id]}
                                        handleToggle={() => handleOnOff(peripheral._id, peripheral.status === 'on' ? 'off' : 'on')}
                                    />
                                </div>
                                <div className="bg-cyan-950 p-2 rounded mt-4 text-center">
                                    <p className={`text-sm font-bold ${peripheral.status === 'on' ? 'text-green-600' : 'text-red-600'}`}>
                                        {peripheral.status === 'on' ? 'Active' : 'Inactive'}
                                    </p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-center text-gray-600">No peripherals found for this pond.</p>
                    )}
                </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 mt-10">
                <h2 className="text-3xl font-bold text-center mb-6">{t('sensor_data_last_24_hour')}</h2>
                <div className="flex flex-wrap -mx-4">
                    {sensorData.map((sensor, index) => (
                        <div key={index} className="w-full md:w-1/2 px-4 mb-10">
                            <h3 className="text-xl font-bold mb-4 text-center">{sensor.sensorType}</h3>
                            <div>
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart data={sensor.data} margin={{top: 20, right: 30, left: 20, bottom: 5}}>
                                        <CartesianGrid strokeDasharray="4 4" stroke="#75976c" strokeWidth={2}/>
                                        <XAxis
                                            dataKey="datetime"
                                            tick={{fill: '#75976c'}}
                                            axisLine={{stroke: '#75976c', strokeWidth: 4}} // Border for X-axis
                                            tickLine={{stroke: '#75976c', strokeWidth: 3}} // Tick line width
                                        />

                                        {/* Y Axis with border width customization */}
                                        <YAxis
                                            tick={{fill: '#75976c'}}
                                            axisLine={{stroke: '#75976c', strokeWidth: 4}} // Border for Y-axis
                                            tickLine={{stroke: '#75976c', strokeWidth: 3}} // Tick line width
                                            domain={
                                                sensor.sensorType === 'Water PH'
                                                    ? [0, 14]
                                                    : sensor.sensorType === 'DO'
                                                        ? [0, 20]
                                                        : sensor.sensorType === 'Water Temp'
                                                            ? [0, 40]
                                                            : sensor.sensorType === 'Ammonia'
                                                                ? [0, 20]
                                                                : [0, 100] // Default range for unspecified sensor types
                                            }
                                        />
                                        <Tooltip contentStyle={{
                                            backgroundColor: '#ffffff',
                                            border: '2px solid #ccc',
                                            fontSize: 18,
                                            fontWeight: 'bold'
                                        }}/>
                                        <Legend/>
                                        {/* Use a different color for each sensor */}
                                        <Line type="monotone" dataKey="averageValue"
                                              strokeWidth={4}
                                              stroke={colors[index % colors.length]}/>
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>

                        </div>
                    ))}
                </div>


            </div>
        </div>
    );
};

const PondInfo = ({icon, label, value}) => (
    <div className="flex items-center">
        <FontAwesomeIcon icon={icon} className="text-blue-500 mr-3"/>
        <p className="text-lg"><strong>{label}:</strong> {value}</p>
    </div>
);

export default PondDetailsPage;
