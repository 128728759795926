import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '@sweetalert2/theme-dark/dark.min.css';

function DataTemperManagement() {
    const [dataTempers, setDataTempers] = useState([]);
    const [formData, setFormData] = useState({
        serial: '',
        tid: '',
        tempered_value: '',
    });
    const [editing, setEditing] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        fetchDataTempers();
    }, []);

    const fetchDataTempers = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/tempering`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setDataTempers(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch data tempers.', 'error');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        try {
            if (editing) {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/api/tempering`, formData, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                Swal.fire('Success', 'Data updated successfully', 'success');
            } else {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/api/tempering`, formData, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                Swal.fire('Success', 'Data added successfully', 'success');
            }
            setFormData({ serial: '', tid: '', tempered_value: '' });
            setEditing(null);
            setIsModalOpen(false);
            fetchDataTempers();
        } catch (error) {
            Swal.fire('Error', 'Failed to save data temper.', 'error');
        }
    };

    const handleEdit = (item) => {
        setFormData({
            serial: item.serial,
            tid: item.tid,
            tempered_value: item.tempered_value,
        });
        setEditing(item);
        setIsModalOpen(true);
    };

    const handleDelete = async (id) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to delete this entry?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!',
            });
            const token = localStorage.getItem('token');
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/tempering/${id}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    Swal.fire('Deleted!', 'Entry has been deleted.', 'success');
                    fetchDataTempers();
                } catch (error) {
                    Swal.fire('Error', 'Failed to delete entry.', 'error');
                }
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to delete entry.', 'error');
        }
    };

    const openModal = () => {
        setFormData({ serial: '', tid: '', tempered_value: '' });
        setEditing(null);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">Data Temper Management</h1>
            <button
                onClick={openModal}
                className="bg-blue-500 text-white p-3 rounded mb-6 hover:bg-blue-600 transition duration-200"
            >
                Add New Entry
            </button>

            <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-2xl font-semibold mb-4">Entries</h2>
                {dataTempers.length > 0 ? (
                    <table className="min-w-full bg-white">
                        <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">Serial</th>
                            <th className="py-2 px-4 border-b">TID</th>
                            <th className="py-2 px-4 border-b">Tempered Value</th>
                            <th className="py-2 px-4 border-b">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataTempers.map((item) => (
                            <tr key={`${item.serial}-${item.tid}`}>
                                <td className="py-2 px-4 border-b text-center">{item.serial}</td>
                                <td className="py-2 px-4 border-b text-center">{item.tid}</td>
                                <td className="py-2 px-4 border-b text-center">{item.tempered_value}</td>
                                <td className="py-2 px-4 border-b text-center">
                                    <button
                                        onClick={() => handleEdit(item)}
                                        className="bg-yellow-500 text-white px-4 py-2 rounded mr-2 hover:bg-yellow-600"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleDelete(item._id)}
                                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No entries found.</p>
                )}
            </div>

            {isModalOpen && (
                <div className="fixed z-50 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                    {editing ? 'Edit Entry' : 'Add New Entry'}
                                </h3>
                                <form onSubmit={handleSubmit} className="space-y-4">
                                    <div>
                                        <label className="block text-gray-700">Serial</label>
                                        <input
                                            type="text"
                                            name="serial"
                                            value={formData.serial}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">TID</label>
                                        <input
                                            type="number"
                                            name="tid"
                                            value={formData.tid}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Tempered Value</label>
                                        <input
                                            type="number"
                                            name="tempered_value"
                                            value={formData.tempered_value}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        />
                                    </div>
                                    <div className="flex justify-end">
                                        <button
                                            type="button"
                                            onClick={closeModal}
                                            className="bg-gray-500 text-white p-3 rounded mr-4 hover:bg-gray-600 transition duration-200"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-blue-500 text-white p-3 rounded hover:bg-blue-600 transition duration-200"
                                        >
                                            {editing ? 'Update Entry' : 'Add Entry'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DataTemperManagement;
