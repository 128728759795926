import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';
import translationBN from './locales/bn/translation.json';

const resources = {
    en: { translation: translationEN },
    bn: { translation: translationBN }
};

i18n
    .use(LanguageDetector) // Detects the user's language
    .use(initReactI18next) // Passes i18n instance to react-i18next
    .init({
        resources,
        fallbackLng: 'en', // Default language
        interpolation: {
            escapeValue: false // React already handles escaping
        }
    });

export default i18n;
